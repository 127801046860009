body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.NotFound_not-found__EyT33{min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin)}

.Login_login-page__mOfvh{padding:2%;margin:auto;height:100vh;background-color:#f9f9f9}[data-theme='dark'] .Login_login-page__mOfvh{background-color:#222}.Login_logo__3R5vb{display:flex;justify-content:center}.Login_logo__3R5vb img{max-width:20rem}.Login_login-form__3w0le{padding-top:20px;display:flex;justify-content:center}.Login_login-button__bD1Po{justify-content:center;padding-top:10px}.Login_login-button__bD1Po button{width:50%}.Login_forgot-password__2AAXu{display:flex;justify-content:center;padding-top:15px}

.IconButton_icon-button__2QGSQ:hover .IconButton_icon__31UgY{opacity:.6}

.MachineConfiguration_page-body__1R4hL{height:100%;border-radius:0;border-right-width:0;border-left-width:0;flex-grow:1;display:flex;flex-direction:column}.MachineConfiguration_config-container__1rahy{padding-right:0;padding-left:0;flex-grow:1;display:flex;flex-direction:column}.MachineConfiguration_side-panel__3xw8e{height:100%;border-radius:0;overflow:auto;position:absolute;top:0;right:0;left:0;bottom:0}

.PageHeader_page-body__3LCcZ{height:100%;border-radius:0;border-right-width:0;border-left-width:0;min-height:100vh;display:flex;flex-direction:column;align-items:stretch}.PageHeader_header__1rb9D{display:flex;flex-direction:row;align-self:center;align-items:center;padding:1.25rem;box-shadow:0 0 5px rgba(0,0,0,0.5);width:100%;position:relative;z-index:10}.PageHeader_header__1rb9D div{align-self:center;flex-grow:1}.PageHeader_header__1rb9D a{align-self:center}

.CenteredSpinner_centered-spinner__2AQKy{height:100%;width:100%;left:0;top:0;pointer-events:none;position:absolute;display:flex;flex-direction:row;align-items:center;justify-content:center}

.CenteredErrorMessage_centered-error__3bsIs{height:100%;width:100%;left:0;top:0;pointer-events:none;position:absolute;display:flex;flex-direction:row;align-items:center;justify-content:center}

.FormHeader_form-buttons__1Vdxc{margin-right:30px;border-radius:10px;min-width:80px}.FormHeader_btn-with-margin__2RrNO{margin-right:30px}

.RedErrorMessage_form-errors__1jhvh{font-size:12px;color:red}

.FormFooter_form-buttons__18TXx{margin-right:1rem;border-radius:10px;margin-left:1rem}.FormFooter_form-button-icons__1pm-N{background:transparent;box-shadow:none;border:none}.FormFooter_form-button-icons__1pm-N:hover{opacity:unset;background:transparent;cursor:unset;box-shadow:none}.FormFooter_form-button-icons__1pm-N:active{background:transparent !important;color:transparent !important;border:none !important;box-shadow:none !important}.FormFooter_form-button-icons__1pm-N:focus{outline:none !important;box-shadow:none !important}.FormFooter_btn-with-margin__1_gQM{margin-right:30px}

.Input_form-inputs__2Egg-{height:38px}.Input_form-errors__6EAcb{font-size:12px;color:red}.Input_error__2rwm3{border-color:red}

.LabelModal_on-top__6rRV8{z-index:1050}.LabelModal_accordion__rAFae{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.LabelModal_header__1ZdTq{margin-bottom:10px}.LabelModal_form-buttons__2VdOO{margin-left:auto;margin-right:10px;border-radius:10px;min-width:80px}.LabelModal_close-button__1ikWG{margin-right:5px}

[data-theme='dark'] .SearchableDropDown_dropdown__3OQXd{color:black}

.Labels_fake-link__uvF-o{cursor:pointer;color:blue}.Labels_fake-link__uvF-o:hover{text-decoration:underline}

.QrCodeManagement_page-body__3wchM{height:100%;border-radius:0;border-right-width:0;border-left-width:0;flex-grow:1;display:flex;flex-direction:column}.QrCodeManagement_config-container__3Y8vm{padding-right:0;padding-left:0;flex-grow:1;display:flex;flex-direction:column}.QrCodeManagement_side-panel__1mf6v{height:100%;border-radius:0;overflow:auto;position:absolute;top:0;right:0;left:0;bottom:0}

.QrCodesModal_on-top__1XBY0{z-index:1050}.QrCodesModal_accordion__2WItw{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.QrCodesModal_header__1ZUYz{margin-bottom:10px}.QrCodesModal_data-point-modal__2MuQD{width:90vw !important;max-width:90vw !important}.QrCodesModal_form-buttons__3P6ql{margin-left:auto;margin-right:10px;border-radius:10px;min-width:80px}.QrCodesModal_close-button__1gcFN{margin-right:5px}.QrCodesModal_modal-content__2ZaT9{width:50%}

.PublicRouter_page-body__2g11t{height:100%;border-radius:0;border-right-width:0;border-left-width:0;flex-grow:1;display:flex;flex-direction:column}.PublicRouter_config-container__1bTOY{padding-right:0;padding-left:0;padding-top:1rem;flex-grow:1;display:flex;flex-direction:column}.PublicRouter_side-panel__1me_-{height:100%;border-radius:0;overflow:auto;position:absolute;top:0;right:0;left:0;bottom:0}.PublicRouter_selection-component___sUu8{padding-top:1rem;justify-content:center;display:flex}.PublicRouter_disclaimer-button__uxqh7{background-color:#d8dee3;border:1px #3b5872;color:black;font-weight:bold}

.dark-mode{color:white}

